export const ROW = 30;
export const COL = 30;
export const SNAKE_START_ROW = 15;
export const SNAKE_START_COL = 15;
export const DIRECTION = {
  LEFT: "left",
  RIGHT: "right",
  UP: "up",
  DOWN: "down",
};

export const STATUS = {
  WIN: "won",
  LOST: "lost",
  ONGOING: "ongoing",
};

export const FOOD_START_ROW = 25;
export const FOOD_START_COL = 1;

export const DIFFICULTY = {
  NOOB: "Noob",
  PRO: "Pro",
};

export const MAZE = {
  None: [],
  Box: CreateBoxMaze(),
  Tunnel: CreateTunnelMaze(),
  Mill: CreateMillMaze(),
  Apartment: CreateApartmentMaze()
};

function CreateApartmentMaze(){
  let ApartmentMaze = [];
  for (let i = 0 ; i < 30; i ++) {
    ApartmentMaze.push({row: 21, col : i});
  }
  for(let i = 0; i < 8; i++){
    ApartmentMaze.push({row: 22+i, col : 16});
  }

  for(let i = 0; i < 13; i++){
    ApartmentMaze.push({row: 13, col : i});
    ApartmentMaze.push({row: 13, col : 29-i});
  }
  for(let i = 0; i < 13; i++){
    ApartmentMaze.push({row: 0, col : 10+i});
    ApartmentMaze.push({row: 12-i, col : 12});
  }

  for(let i = 0; i < 12; i++){
    ApartmentMaze.push({row: 0, col : 10+i});
    ApartmentMaze.push({row: 12-i, col : 12});
  }

  for(let i = 0; i < 6; i++){
    ApartmentMaze.push({row: 0, col : i});
    ApartmentMaze.push({row: i, col : 0});
  }


  return ApartmentMaze;
}

function CreateMillMaze(){
  let MillMaze = [];
  for (let i = 0; i < 16 ; i ++){
    MillMaze.push({row: i, col: 8});
    MillMaze.push({row: 29-i, col: 21});
  }

  for(let i = 0 ; i < 9; i ++){
    MillMaze.push({row: 20, col: i});
    MillMaze.push({row: 10, col: 29-i});
  }
  return MillMaze;
}

function CreateTunnelMaze(){
  let TunnelMaze = [];
  for(let i = 0; i < 10; i++){
    TunnelMaze.push({row:0, col: i});
    TunnelMaze.push({row:i, col: 0})
    TunnelMaze.push({row:0, col: 29-i});
    TunnelMaze.push({row:i, col: 29});
    TunnelMaze.push({row:29-i, col: 0});
    TunnelMaze.push({row:29, col: i});
    TunnelMaze.push({row:29, col: 29-i});
    TunnelMaze.push({row:29-i, col: 29});
  }
  for(let i= 0; i < 14; i++){
    TunnelMaze.push({row:12, col:8+i});
    TunnelMaze.push({row:17, col:8+i});

  }
  return TunnelMaze;
}

function CreateBoxMaze() {
  let BoxMaze = [];
  for (let i = 0; i < COL; i++) {
    BoxMaze.push({ row: 0, col: i });
  }
  for (let i = 1; i < ROW; i++) {
    BoxMaze.push({ row: i, col: 29 });
  }

  for (let i = 28; i >= 0; i--) {
    BoxMaze.push({ row: 29, col: i });
  }

  for (let i = 28; i > 0; i--) {
    BoxMaze.push({ row: i, col: 0 });
  }

  return BoxMaze;
}
