import React from 'react';


// function UnmemoizedCell(props){
//     const {row, col, isFood, isSnake, isWall} = props
//     const ClassNameFromProp = `${isFood ? 'foodCell' : ''} ${isSnake ? 'snakeCell' : ''} ${isWall ? 'wallCell' : ''}`;
//     return(
//         <div className = {`cell-${props.row}-${props.col} ${ClassNameFromProp}`} >
//         </div>
//     );
// }

const Cell = React.memo((props) => {
    const {row, col, isFood, isSnake, isWall} = props
    const ClassNameFromProp = `${isFood ? 'foodCell' : ''} ${isSnake ? 'snakeCell' : ''} ${isWall ? 'wallCell' : ''}`;
    return(
        <div className = {`cell-${props.row}-${props.col} ${ClassNameFromProp}`} >
        </div>
    );
});

export default Cell;