import { Button, Typography } from "@material-ui/core";
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Cell from "./Cell";
import {
  ROW,
  COL,
  SNAKE_START_ROW,
  SNAKE_START_COL,
  DIRECTION,
  STATUS,
  FOOD_START_ROW,
  FOOD_START_COL,
  DIFFICULTY,
  MAZE,
} from "./Constants";
import { getRndInteger } from "./Utils";

function Board(props) {
  const [board, setBoard] = useState(InitializeBoard);
  const [snake, setSnake] = useState([
    {
      row: SNAKE_START_ROW,
      col: SNAKE_START_COL - 1,
    },
    {
      row: SNAKE_START_ROW,
      col: SNAKE_START_COL,
    },
  ]);
  const [direction, setDirection] = useState(DIRECTION.RIGHT);
  const [status, setStatus] = useState(STATUS.ONGOING);
  const [food, setFood] = useState({
    row: FOOD_START_ROW,
    col: FOOD_START_COL,
  });

  const [score, setScore] = useState(0);

  const stateRef = useRef();
  stateRef.current = {
    board: board,
    snake: snake,
    direction: direction,
    food: food,
    keypressed: false,
    atefood: false,
  };
  useInterval(
    () => {
      MoveSnake();
    },
    status === STATUS.ONGOING ? 17 : null
  );

  useEffect(() => {
    window.addEventListener("keydown", (e) => {
      handleKeydown(e);
    });
  }, []);

  //useEffect to draw maze here

  useEffect(() => {
    StartGame();
    console.log('started game');
  }, [props.maze]);     

  function handleKeydown(e) {
    if (stateRef.current.keypressed) return;
    const newDirection = GetDirectionFromKey(e.key);
    if (!IsValidDirection(newDirection)) return;
    setDirection(newDirection);
    stateRef.current.keypressed = true;
  }

  function IsValidDirection(newDirection) {
    if (newDirection === "") return false;
    // Check if Opposite direction i.e: Cant go into urself
    var OppositeDirections = {
      up: "down",
      right: "left",
      down: "up",
      left: "right",
    };
    if (OppositeDirections[stateRef.current.direction] === newDirection) {
      return false;
    }

    return true;
  }
  function GetDirectionFromKey(key) {
    if (key === "ArrowUp") return DIRECTION.UP;
    if (key === "ArrowRight") return DIRECTION.RIGHT;
    if (key === "ArrowDown") return DIRECTION.DOWN;
    if (key === "ArrowLeft") return DIRECTION.LEFT;
    return "";
  }

  function InitializeBoard() {
    const board = [];
    for (let r = 0; r < ROW; r++) {
      const currentRow = [];
      for (let c = 0; c < COL; c++) {
        currentRow.push(CreateCell(r, c));
      }
      board.push(currentRow);
    }
    return board;
  }

  //   useEffect(() => {
  //       console.log(board);
  //       console.log(snake);
  //   })

  function StartGame() {
    setBoard(InitializeBoard());
    setSnake([
      {
        row: SNAKE_START_ROW,
        col: SNAKE_START_COL - 1,
      },
      {
        row: SNAKE_START_ROW,
        col: SNAKE_START_COL,
      },
    ]);
    setDirection(DIRECTION.RIGHT);
    setStatus(STATUS.ONGOING);
    setFood({
      row: FOOD_START_ROW,
      col: FOOD_START_COL,
    });
    setScore(0);
  }

  /** need to synchronously animate the wall */
  useLayoutEffect(() => {
    //clear previous maze
    let Cells = Array.from(document.querySelectorAll('.playing-area div'));
    for(let i = 0; i < Cells.length; i ++){
      Cells[i].style.animation = "";
    }
    let wallArray = MAZE[props.maze];
    console.log(wallArray);
    for (let i = 0; i < wallArray.length; i++) {
      setTimeout(() => {
        document.querySelector(`.cell-${wallArray[i].row}-${wallArray[i].col}`).style.animation = "animate-wall 0.3s forwards";
        // document.querySelector(`.cell-${wallArray[i].row}-${wallArray[i].col}`).classList.add("animate-wall");
      }, 10 * i);
    }
    
  }, [props.maze]);

  function MoveSnake() {
    var CurrentBoard = stateRef.current.board.slice();
    var CurrentDirection = stateRef.current.direction;
    var CurrentSnake = stateRef.current.snake.slice();
    var SnakeHead = {
      row: CurrentSnake[CurrentSnake.length - 1].row,
      col: CurrentSnake[CurrentSnake.length - 1].col,
    };

    var SnakeTail = {
      row: CurrentSnake[0].row,
      col: CurrentSnake[0].col,
    };

    var NextHead = GetNextHead(CurrentDirection, SnakeHead);
    HandleHeadEvents(NextHead, CurrentBoard, CurrentSnake, CurrentDirection);

    //push differently for a reversed snake

    if (props.difficulty === DIFFICULTY.PRO && stateRef.current.atefood) {
      CurrentSnake.unshift(NextHead);
      CurrentBoard[CurrentSnake[CurrentSnake.length - 1].row][
        CurrentSnake[CurrentSnake.length - 1].col
      ].isSnake = false;
      CurrentSnake.pop();
      CurrentBoard[NextHead.row][NextHead.col].isSnake = true;
    } else {
      CurrentSnake.push(NextHead);
      CurrentBoard[CurrentSnake[0].row][CurrentSnake[0].col].isSnake = false;
      CurrentSnake.shift();
      CurrentBoard[NextHead.row][NextHead.col].isSnake = true;
    }

    //snake position has been updated

    setSnake(CurrentSnake);
    setBoard(CurrentBoard);
  }

  function GetNextHead(Direction, Head) {
    var NextHead;
    if (Direction === DIRECTION.RIGHT) {
      NextHead = {
        row: Head.row,
        col: Head.col + 1,
      };
    } else if (Direction === DIRECTION.LEFT) {
      NextHead = {
        row: Head.row,
        col: Head.col - 1,
      };
    } else if (Direction === DIRECTION.UP) {
      NextHead = {
        row: Head.row - 1,
        col: Head.col,
      };
    } else if (Direction === DIRECTION.DOWN) {
      NextHead = {
        row: Head.row + 1,
        col: Head.col,
      };
    }

    return NextHead;
  }

  function HandleHeadEvents(
    nextHead,
    CurrentBoard,
    CurrentSnake,
    CurrentDirection
  ) {
    //go to opposite when out of bounds
    if (nextHead.row > ROW - 1) nextHead.row = 0;
    else if (nextHead.col > COL - 1) nextHead.col = 0;
    else if (nextHead.row < 0) nextHead.row = ROW - 1;
    else if (nextHead.col < 0) nextHead.col = COL - 1;
    //Handle Food comsumption
    if (
      stateRef.current.food.row === nextHead.row &&
      stateRef.current.food.col === nextHead.col
    ) {
      HandleFoodConsumption(CurrentBoard, CurrentSnake, CurrentDirection);
    }
    //Handle Snake Collisions
    if (
      CurrentBoard[nextHead.row][nextHead.col].isSnake ||
      CurrentBoard[nextHead.row][nextHead.col].isWall
    ) {
      setStatus(STATUS.LOST);
    }
  }

  function HandleFoodConsumption(CurrentBoard, CurrentSnake, CurrentDirection) {
    GrowSnake(CurrentBoard, CurrentSnake, CurrentDirection);
    if (props.difficulty === DIFFICULTY.PRO) {
      ReverseSnakeDirection(CurrentSnake);
      CurrentSnake.reverse();
    }
    setScore(score + 1);
    CurrentBoard[stateRef.current.food.row][
      stateRef.current.food.col
    ].isFood = false;
    RandomFoodPosition(CurrentBoard);
  }

  function ReverseSnakeDirection(CurrentSnake) {
    let SnakeTailDirection;
    let TailCell = CurrentSnake[0];
    let ClosestTailCell = CurrentSnake[1];
    if (TailCell.row - ClosestTailCell.row === 1) {
      setDirection(DIRECTION.DOWN);
    } else if (TailCell.row - ClosestTailCell.row === -1) {
      setDirection(DIRECTION.UP);
    } else if (TailCell.col - ClosestTailCell.col === 1) {
      setDirection(DIRECTION.RIGHT);
    } else if (TailCell.col - ClosestTailCell.col === -1) {
      setDirection(DIRECTION.LEFT);
    }
  }

  function RandomFoodPosition(CurrentBoard) {
    var r = getRndInteger(0, ROW);
    var c = getRndInteger(0, COL);
    while (CurrentBoard[r][c].isSnake || CurrentBoard[r][c].isWall) {
      r = getRndInteger(0, ROW);
      c = getRndInteger(0, COL);
    }
    CurrentBoard[r][c].isFood = true;
    setFood({
      row: r,
      col: c,
    });
    stateRef.current.atefood = true;
  }

  function GrowSnake(CurrentBoard, CurrentSnake, CurrentDirection) {
    // Grow tail to the opposite of where the tail is moving
    var SnakeHead = {
      row: CurrentSnake[CurrentSnake.length - 1].row,
      col: CurrentSnake[CurrentSnake.length - 1].col,
    };
    var SnakeTail = {
      row: CurrentSnake[0].row,
      col: CurrentSnake[0].col,
    };

    var newSnakeTail = {
      row: CurrentSnake[0].row - (CurrentSnake[1].row - CurrentSnake[0].row),
      col: CurrentSnake[0].col - (CurrentSnake[1].col - CurrentSnake[0].col),
    };

    if (newSnakeTail.row > ROW - 1) newSnakeTail.row = 0;
    else if (newSnakeTail.col > COL - 1) newSnakeTail.col = 0;
    else if (newSnakeTail.row < 0) newSnakeTail.row = ROW - 1;
    else if (newSnakeTail.col < 0) newSnakeTail.col = COL - 1;
    CurrentSnake.unshift(newSnakeTail);
    CurrentBoard[newSnakeTail.row][newSnakeTail.col].isSnake = true;
  }

  function CreateCell(row, col) {
    return {
      row,
      col,
      isSnake:
        row === SNAKE_START_ROW &&
        (col === SNAKE_START_COL || col === SNAKE_START_COL - 1),
      isFood: row === FOOD_START_ROW && col === FOOD_START_COL,
      isWall: MAZE[props.maze].some(
        (wall) => wall.row === row && wall.col === col
      ),
    };
  }

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  return (
    <div className="game-area">
      <Typography variant="h6" align="center">
        Score: {score}
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        onClick={StartGame}
        disabled={status === "ongoing" ? true : false}
        className="start-button"
      >
        Start Game
      </Button>
      <div className="playing-area">
        {board.map((row, rowIndex) =>
          row.map((cell, cellIndex) => (
            <Cell
              key={`${rowIndex}-${cellIndex}`}
              row={rowIndex}
              col={cellIndex}
              isSnake={cell.isSnake}
              isFood={cell.isFood}
              isWall={cell.isWall}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default Board;
