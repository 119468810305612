import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Paper, Switch, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Logo } from "./logo/logo.svg";
import { amber, yellow } from "@material-ui/core/colors";
import Board from "./Board";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { DIFFICULTY } from "./Constants";

const theme = createTheme({
  palette: {
    type: "dark",
    background: {
      paper: "black",
      default: "black",
    },
    primary: yellow,
  },
});

const useStyles = makeStyles({
  fullscreen: {
    minHeight: "100vh",
  },
});

function App() {
  const classes = useStyles();
  const [difficulty, setDifficulty] = useState(DIFFICULTY.NOOB);
  const [maze, setMaze] = useState("None");
  const [darkmode, setdarkmode] = useState(true);

  let theme = createTheme({
    palette: {
      type: darkmode ? "dark" : "light",
      primary: yellow,
    },
  });

  if (darkmode) {
    theme = createTheme({
      palette: {
        type: darkmode ? "dark" : "light",
        primary: yellow,
        background: {
          paper: "black",
          default: "black",
        },
      },
    });
  } else {
    theme = createTheme({
      palette: {
        type: "light",
        primary: amber,
      },
    });
  }

  function ChangeDifficulty(e) {
    setDifficulty(e.target.innerText);
  }

  function ChangeMaze(e) {
    setMaze(e.target.innerText);
  }

  return (
    <ThemeProvider theme={theme}>
      <Paper square className={classes.fullscreen}>
        <Navbar>
          <NavItem
            name="Difficulty"
            SelectionHandler={(e) => ChangeDifficulty(e)}
          ></NavItem>
          <NavItem
            name="Maze"
            SelectionHandler={(e) => ChangeMaze(e)}
          ></NavItem>
          <Switch checked={darkmode} onChange={() => setdarkmode(!darkmode)} />
        </Navbar>
        <div className="Page">
          <Board difficulty={difficulty} maze={maze} />
        </div>
      </Paper>
    </ThemeProvider>
  );
}

function Navbar(props) {
  return (
    <nav className="navbar">
      <div className="logo">
        <Logo className="logo-logo" />
        <Typography variant="h3">Snake</Typography>
      </div>
      <ul className="navbar-nav">{props.children}</ul>
    </nav>
  );
}

function NavItem(props) {
  const [active, setActive] = useState(false);
  function ToggleDropdownMenu(e) {
    e.preventDefault();
    setActive(!active);
  }
  function SelectionHandler(e) {
    ToggleDropdownMenu(e);
    props.SelectionHandler(e);
  }

  const dropdownMenuName = props.name;
  let DropdownMenuToDisplay;
  if (dropdownMenuName === "Difficulty") {
    DropdownMenuToDisplay = (
      <DropdownMenu className={`${active ? "active" : ""}`}>
        <li className="DropdownMenu-item" onClick={SelectionHandler}>
          Noob
        </li>
        <li className="DropdownMenu-item" onClick={SelectionHandler}>
          Pro
        </li>
      </DropdownMenu>
    );
  } else if (dropdownMenuName === "Maze") {
    DropdownMenuToDisplay = (
      <DropdownMenu className={`${active ? "active" : ""} DropdownMenu-maze`}>
        <li className="DropdownMenu-item" onClick={SelectionHandler}>
          None
        </li>
        <li className="DropdownMenu-item" onClick={SelectionHandler}>
          Box
        </li>
        <li className="DropdownMenu-item" onClick={SelectionHandler}>
          Tunnel
        </li>
        <li className="DropdownMenu-item" onClick={SelectionHandler}>
          Mill
        </li>
        <li className="DropdownMenu-item" onClick={SelectionHandler}>
          Apartment
        </li>
      </DropdownMenu>
    );
  } else if (dropdownMenuName === "Dark Theme") {
    DropdownMenuToDisplay = "";
  }

  return (
    <li style={{ position: "relative" }}>
      <a
        href="#"
        className={`navitem-button ${active ? "active" : ""}`}
        onClick={ToggleDropdownMenu}
      >
        <Typography variant="h6" color="textPrimary">
          {props.name}
        </Typography>
        <ArrowDropDownIcon className="arrowdown-icon" color="primary" />
      </a>
      {DropdownMenuToDisplay}
    </li>
  );
}

function DropdownMenu(props) {
  return (
    <ul className={`DropdownMenu ${props.className}`}>{props.children}</ul>
  );
}

export default App;
